import { ApiResponse, api } from '@/lib/api';
import {
  ICashFlowData,
  IDashboardFilters,
  IProductData,
  IProductStatisticsFilters,
  IUserBalanceData,
} from './dashboard.types';

export const dashboardRepo = {
  getCashFlowStatistics: (params: IDashboardFilters): ApiResponse<ICashFlowData[]> => {
    return api.get('/user/bo/dashboard/payments', {
      params,
    });
  },
  getProductStatistics: (params: IProductStatisticsFilters): ApiResponse<IProductData[]> => {
    return api.get('/user/bo/dashboard/transactions', {
      params,
    });
  },
  getUserBalanceStatistics: (params: IDashboardFilters): ApiResponse<IUserBalanceData[]> => {
    return api.get('/user/bo/dashboard/user-balance', {
      params,
    });
  },
};
