
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';

import { WithDataTable } from '@/ui/mixins/WithDataTable';
import { DashboardStore } from '@/modules/dashboard/dashboard.store';
import { percentageFormatter } from '@/ui/components/CellRenderers/PercentageValueFormatter';

@Component({
  name: 'CashFlowTable',
})
export default class CashFlowTable extends Mixins(WithDataTable) {
  get tableProps() {
    return {
      columnDefs: [
        {
          headerName: '',
          children: [
            {
              headerName: this.$t('dashboard.paymentMethod'),
              field: 'transaction_origin',
              width: 140,
            },
          ],
        },
        {
          headerName: this.$t('dashboard.webCashFlow'),
          children: [
            {
              headerName: this.$t('dashboard.users'),
              field: 'user_count',
              width: 140,
            },
            {
              headerName: this.$t('dashboard.in'),
              field: 'deposit_total',
              width: 140,
            },
            {
              headerName: this.$t('dashboard.inOut'),
              field: 'ggr',
              width: 140,
            },
            {
              headerName: this.$t('dashboard.margin'),
              field: 'margin',
              flex: 1,
              valueFormatter: percentageFormatter,
            },
          ],
        },
      ],
      rowModelType: 'infinite',
    };
  }

  @Prop()
  monthAndYear!: string[];

  @Prop()
  year!: number;

  @Prop()
  filterDateType!: string;

  @Watch('year')
  onYearChanged() {
    if (this.filterDateType === 'monthAndYear') return;
    this.gridApi?.setDatasource(this.dataSource);
  }

  @Watch('monthAndYear')
  onFiltersChanged() {
    if (this.filterDateType === 'yearOnly') return;
    this.gridApi?.setDatasource(this.dataSource);
  }

  get tableData() {
    return DashboardStore.cashFlowStatistics;
  }

  get totalRecords() {
    return DashboardStore.cashFlowStatistics.length;
  }

  get gridOptions() {
    return {
      getRowStyle: (params: any) => {
        if (params.node.rowIndex === 0) return { fontWeight: 'bold' };
      },
    };
  }

  async fetchData() {
    this.gridApi?.showLoadingOverlay();
    const params: { [key: string]: number | string } = {};
    if (this.filterDateType === 'monthAndYear' && this.monthAndYear.length !== 0) {
      params.month = this.monthAndYear[0];
      params.year = this.monthAndYear[1];
    }
    if (this.filterDateType === 'yearOnly' && this.year) params.year = this.year;
    await DashboardStore.fetchCashFlowStatistics(params);
  }
}
