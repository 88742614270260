
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';

import { WithDataTable } from '@/ui/mixins/WithDataTable';
import { DashboardStore } from '@/modules/dashboard/dashboard.store';
import { percentageFormatter } from '@/ui/components/CellRenderers/PercentageValueFormatter';

@Component({
  name: 'ProductTable',
})
export default class ProductTable extends Mixins(WithDataTable) {
  get tableProps() {
    return {
      columnDefs: [
        {
          headerName: '',
          children: [
            {
              headerName: this.$t('dashboard.product'),
              field: 'transaction_origin',
              width: 140,
            },
          ],
        },
        {
          headerName: this.currentChannelLabel,
          children: [
            {
              headerName: this.$t('dashboard.usersNumber'),
              field: 'user_count',
              width: 140,
            },
            {
              headerName: this.$t('dashboard.shopsNumber'),
              field: 'shop_count',
              width: 140,
            },
          ],
        },
        {
          headerName: this.$t('dashboard.bettingOrGambling'),
          children: [
            {
              headerName: this.$t('dashboard.bet'),
              field: 'total_bet',
              width: 140,
            },
            {
              headerName: this.$t('dashboard.ggr'),
              field: 'ggr',
              width: 140,
            },
            {
              headerName: this.$t('dashboard.margin'),
              field: 'margin',
              flex: 1,
              valueFormatter: percentageFormatter,
            },
          ],
        },
      ],
      rowModelType: 'infinite',
    };
  }

  @Prop()
  monthAndYear!: string[];

  @Prop()
  year!: number;

  @Prop()
  channel!: string;

  @Prop()
  filterDateType!: string;

  currentChannelLabel = 'All';

  @Watch('year')
  onYearChanged() {
    if (this.filterDateType === 'monthAndYear') return;
    this.gridApi?.setDatasource(this.dataSource);
  }

  @Watch('monthAndYear')
  onFiltersChanged() {
    if (this.filterDateType === 'yearOnly') return;
    this.gridApi?.setDatasource(this.dataSource);
  }

  @Watch('channel')
  onChannelChanged() {
    this.currentChannelLabel = this.getCurrentChannelLabel() as string;
    this.gridApi?.setDatasource(this.dataSource);
  }

  get tableData() {
    return DashboardStore.productStatistics;
  }

  get totalRecords() {
    return DashboardStore.productStatistics.length;
  }

  get gridOptions() {
    return {
      getRowStyle: (params: any) => {
        if (params.node.rowIndex === 0) return { fontWeight: 'bold' };
      },
    };
  }

  getCurrentChannelLabel() {
    if (!this.channel || this.channel === 'All') return this.$t('dashboard.all');
    if (this.channel === 'web') return this.$t('dashboard.web');
    return this.$t('dashboard.retail');
  }

  async fetchData() {
    this.gridApi?.showLoadingOverlay();
    const params: { [key: string]: number | string } = {};
    if (this.filterDateType === 'monthAndYear' && this.monthAndYear.length !== 0) {
      params.month = this.monthAndYear[0];
      params.year = this.monthAndYear[1];
    }
    if (this.filterDateType === 'yearOnly' && this.year) params.year = this.year;
    if (this.channel && this.channel !== 'All') params.channel = this.channel;
    await DashboardStore.fetchProductStatistics(params);
  }
}
