import { dashboardRepo } from './dashboard.repo';
import {
  IDashboardFilters,
  IWithTransactionOrigin,
  IProductStatisticsFilters,
  IUserBalanceData,
  IProductData,
  ICashFlowData,
} from './dashboard.types';

export const dashboardService = {
  getCashFlowStatistics: (params: IDashboardFilters) => {
    return dashboardRepo.getCashFlowStatistics(params);
  },
  getProductStatistics: (params: IProductStatisticsFilters) => {
    return dashboardRepo.getProductStatistics(params);
  },
  getUserBalanceStatistics: (params: IDashboardFilters) => {
    return dashboardRepo.getUserBalanceStatistics(params);
  },
  formatMissingStatistics(
    data: IWithTransactionOrigin[],
    potentialMissingValues: string[],
    emptyStatisticsObject: any
  ) {
    const formattedStatistics = [...data];
    potentialMissingValues.forEach(value => {
      if (!formattedStatistics.some(statistic => statistic.transaction_origin === value))
        formattedStatistics.push({ ...emptyStatisticsObject, transaction_origin: value });
    });
    return formattedStatistics;
  },
  reorderOverallToTop(
    dataArr: IUserBalanceData[] | IProductData[] | ICashFlowData[],
    keyName: 'transaction_origin' | 'status'
  ) {
    // @ts-ignore
    const reorderedArr = [];
    dataArr.forEach((data: IUserBalanceData | IProductData | ICashFlowData) => {
      // @ts-ignore
      if (data[keyName] === 'Overall') reorderedArr.unshift(data);
      else reorderedArr.push(data);
    });
    // @ts-ignore
    return reorderedArr;
  },
};
