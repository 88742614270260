export const emptyProductStatistic = {
  user_count: 0,
  shop_count: 0,
  total_bet: 0,
  total_won: 0,
  ggr: 0,
  margin: 0,
};

export const porductStatisticsTransactionValues = ['Casino', 'SportsBetting', 'Virtuals'];

export const emptyCashFlowStatistics = {
  user_count: 0,
  shop_count: 0,
  deposit_total: 0,
  amount_won: 0,
  ggr: 0,
  margin: 0,
};

export const cashFlowTransactionValues = [
  'Shop',
  'I-Pay',
  'Dobar Komsija',
  'Bank Transfer',
  'Credit Card',
];
