import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import to from 'await-to-js';

import { dashboardService } from './dashboard.service';
import store from '@/store';
import {
  IDashboardFilters,
  ICashFlowData,
  IProductData,
  IUserBalanceData,
  IProductStatisticsFilters,
} from './dashboard.types';
import {
  emptyProductStatistic,
  emptyCashFlowStatistics,
  porductStatisticsTransactionValues,
  cashFlowTransactionValues,
} from './dashboard.constants';

@Module({ dynamic: true, store, name: 'dashboard', namespaced: true })
class Dashboard extends VuexModule {
  cashFlowStatistics: ICashFlowData[] = [];
  productStatistics: IProductData[] = [];
  userBalanceStatistics: IUserBalanceData[] = [];

  @Mutation
  setCashFlowStatistics(payload: ICashFlowData[]) {
    this.cashFlowStatistics = payload;
  }

  @Mutation
  setProductStatistics(payload: IProductData[]) {
    this.productStatistics = payload;
  }

  @Mutation
  setUserBalanceStatistics(payload: IUserBalanceData[]) {
    this.userBalanceStatistics = payload;
  }

  @Action
  async fetchCashFlowStatistics(params: IDashboardFilters) {
    const [err, res] = await to(dashboardService.getCashFlowStatistics(params));
    if (err || !res) return Promise.reject(err);
    const formattedCashFlowStatistics = dashboardService.formatMissingStatistics(
      res,
      cashFlowTransactionValues,
      emptyCashFlowStatistics
    ) as ICashFlowData[];
    const reorderedCashFlowStatistics = dashboardService.reorderOverallToTop(
      formattedCashFlowStatistics,
      'transaction_origin'
    );
    this.setCashFlowStatistics(reorderedCashFlowStatistics);

    return Promise.resolve(formattedCashFlowStatistics);
  }

  @Action
  async fetchProductStatistics(params: IProductStatisticsFilters) {
    const [err, res] = await to(dashboardService.getProductStatistics(params));
    if (err || !res) return Promise.reject(err);
    const formattedProductStatistics = dashboardService.formatMissingStatistics(
      res,
      porductStatisticsTransactionValues,
      emptyProductStatistic
    ) as IProductData[];
    const reorderedProductStatistics = dashboardService.reorderOverallToTop(
      formattedProductStatistics,
      'transaction_origin'
    );
    this.setProductStatistics(reorderedProductStatistics);

    return Promise.resolve(formattedProductStatistics);
  }

  @Action
  async fetchUserBalanceStatistics(params: IDashboardFilters) {
    const [err, res] = await to(dashboardService.getUserBalanceStatistics(params));
    if (err || !res) return Promise.reject(err);

    const reorderedUserBalanceStatistics = dashboardService.reorderOverallToTop(res, 'status');
    this.setUserBalanceStatistics(reorderedUserBalanceStatistics);

    return Promise.resolve(reorderedUserBalanceStatistics);
  }
}

export const DashboardStore = getModule(Dashboard);
