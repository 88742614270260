
import { generateSelectOptionsFromArr } from '@/lib/generateSelectOptionsFromArr';
import dayjs from 'dayjs';
import { Component, Vue } from 'vue-property-decorator';

import CashFlowTable from './components/CashFlowTable.vue';
import ProductTable from './components/ProductTable.vue';
import UserBalanceTable from './components/UserBalanceTable.vue';

@Component({
  name: 'Dashboard',
  components: {
    CashFlowTable,
    ProductTable,
    UserBalanceTable,
  },
})
export default class DashboardPage extends Vue {
  filterDateType: 'monthAndYear' | 'yearOnly' = 'monthAndYear';
  year = '';
  monthAndYear: string[] = [];
  monthAndYearForReset = '';
  channel = '';
  yearOnly = '';
  channelOptions = generateSelectOptionsFromArr(['All', 'web', 'retail']);
  defaultYearFilter = `Year ${new Date().getFullYear()}`;
  formattedCurrentDate = this.defaultYearFilter;

  handleChangeMonth(date: any) {
    if (!date) {
      this.monthAndYear = [];
      return;
    }
    this.filterDateType = 'monthAndYear';

    this.monthAndYearForReset = date;
    this.yearOnly = '';

    const month = (dayjs(date).month() + 1).toString();

    const year = dayjs(date)
      .year()
      .toString();
    this.monthAndYear = [month, year];

    const monthName = dayjs(date).format('MMMM');

    this.formattedCurrentDate = `${monthName} ${year}`;
  }

  handleChangeYear() {
    this.filterDateType = 'yearOnly';
    this.year = this.yearOnly;
    this.monthAndYearForReset = '';
    this.formattedCurrentDate = `Year ${this.year}`;
  }

  handleChangeChannel(value: string) {
    this.channel = value;
  }

  get showResetBtn() {
    return this.formattedCurrentDate !== this.defaultYearFilter;
  }

  handleResetFilters() {
    this.monthAndYearForReset = '';
    this.yearOnly = '';
    this.formattedCurrentDate = this.defaultYearFilter;
    this.filterDateType = 'yearOnly';
    this.year = new Date().getFullYear().toString();
  }
}
