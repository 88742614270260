
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';

import { WithDataTable } from '@/ui/mixins/WithDataTable';
import { DashboardStore } from '@/modules/dashboard/dashboard.store';

@Component({
  name: 'UserBalanceTable',
})
export default class UserBalanceTable extends Mixins(WithDataTable) {
  get tableProps() {
    return {
      columnDefs: [
        {
          headerName: '',
          children: [
            {
              headerName: this.$t('dashboard.status'),
              field: 'status',
              width: 140,
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: this.$t('dashboard.usersNumber'),
              field: 'users_count',
              width: 140,
            },
          ],
        },
        {
          headerName: this.$t('dashboard.standardBalance'),
          children: [
            {
              headerName: this.$t('dashboard.start'),
              field: 'starting_balance',
              width: 140,
            },
            {
              headerName: this.$t('dashboard.actual'),
              field: 'standard_actual_balance',
              width: 140,
            },
          ],
        },
        {
          headerName: this.$t('dashboard.promoBalance'),
          children: [
            {
              headerName: this.$t('dashboard.start'),
              field: 'starting_promo_balance',
              width: 140,
            },
            {
              headerName: this.$t('dashboard.actual'),
              field: 'promo_actual_balance',
              flex: 1,
            },
          ],
        },
      ],
      rowModelType: 'infinite',
    };
  }

  @Prop()
  monthAndYear!: string[];

  @Prop()
  year!: number;

  @Prop()
  filterDateType!: string;

  @Watch('year')
  onYearChanged() {
    if (this.filterDateType === 'monthAndYear') return;
    this.gridApi?.setDatasource(this.dataSource);
  }

  @Watch('monthAndYear')
  onFiltersChanged() {
    if (this.filterDateType === 'yearOnly') return;
    this.gridApi?.setDatasource(this.dataSource);
  }

  get tableData() {
    return DashboardStore.userBalanceStatistics;
  }

  get totalRecords() {
    return DashboardStore.userBalanceStatistics.length;
  }

  get gridOptions() {
    return {
      getRowStyle: (params: any) => {
        if (params.node.rowIndex === 0) return { fontWeight: 'bold' };
      },
    };
  }

  async fetchData() {
    this.gridApi?.showLoadingOverlay();
    const params: { [key: string]: number | string } = {};
    if (this.filterDateType === 'monthAndYear' && this.monthAndYear.length !== 0) {
      params.month = this.monthAndYear[0];
      params.year = this.monthAndYear[1];
    }
    if (this.filterDateType === 'yearOnly' && this.year) params.year = this.year;
    await DashboardStore.fetchUserBalanceStatistics(params);
  }
}
